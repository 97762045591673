/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from "react";
import styles from "./LastSeenFloating.module.css";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";

const BadgeIcon = (props) => {

  const StyledOnlineBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const StyledOfflineBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#ba000d",
      color: "#ba000d",
      //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  
  return (
    <Chip
      size="medium"
      sx={{ margin: 1 }}
      avatar={
        props.online ? (
          <StyledOnlineBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {props.icon}
          </StyledOnlineBadge>
        ) : (
          <StyledOfflineBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {props.icon}
          </StyledOfflineBadge>
        )
      }
      label={props.label}
    />
  );
}


const LastSeenFloating = () => {
  const [Sens1Lastseen, setSens1Lastseen] = useState("Loading ...");
  const [Sens2Lastseen, setSens2Lastseen] = useState("Loading ...");
  const [Loading, setLoading] = useState(true);
  const [Sens1Online, setSens1Online] = useState(false)
  const [Sens2Online, setSens2Online] = useState(false)

  const fetchLastSeen = () => {
    setLoading(true);

    //   Fetching data
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://dashboard.grearoof.com/api/last-updated", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSens1Lastseen(moment(result.sensor1).fromNow());
        setSens2Lastseen(moment(result.sensor2).fromNow());
      })
      .catch((error) => {
        console.log("error", error);
      });

    //   Fetching sensor 1
    fetch(`https://new-dash.grearoof.com/cutting-sensor?v=${Math.random()}`)
      .then((response) => response.status === 200 ? setSens1Online(true) : setSens1Online(false))
      .catch((error) => setSens1Online(false));

    //   Fetching sensor 2
    fetch(`https://new-dash.grearoof.com/gate-sensor?v=${Math.random()}`)
      .then((response) => response.status === 200 ? setSens2Online(true) : setSens2Online(false))
      .catch((error) => setSens2Online(false));
    
    ;
      
    setLoading(false);
  };

  useEffect(() => {
    fetchLastSeen();
    setInterval(fetchLastSeen, 5000);
  }, []);
  return (
    <Box
      sx={{
        // position: "relative",
        // bottom: 16,
        // right: 16,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <BadgeIcon
        label={Sens1Lastseen}
        icon={
          <ContentCutIcon
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "unset",
            }}
          />
        }
        online={Sens1Online}
      />

      <IconButton aria-label="refresh" className={`${Loading && styles.spin}`}>
        <CachedIcon fontSize="inherit" />
      </IconButton>

      <BadgeIcon
        label={Sens2Lastseen}
        icon={
          <DoorSlidingIcon
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "unset",
            }}
        />
        }
        online={Sens2Online}
      />
    </Box>
  );
};

export default LastSeenFloating;

/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from "react";
import styles from "./Home.css";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";

const Home = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [from, setFrom] = React.useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [to, setTo] = React.useState(new Date());
  const [data, setData] = useState(null);
  const [threshold, setThreshold] = useState(60);

  const handleChange = (event, value, target) => {
    console.log("Handle Change : ", event, value, target);
    switch (target) {
      case "from":
        setFrom(value);
        break;
      case "to":
        setTo(value);
        break;
      case "tab":
        setTabValue(value);
        break;
      case "threshold":
        setThreshold(value);
        break;
    }
  };

  const handleQuickSet = (hours) => {
    setTo(new Date());
    setFrom(new Date(new Date().setHours(new Date().getHours() - hours)));
  };

  const gateSensorCounter = (data) => {
    let count = 0;
    data.map((e, i, a) => {
      if (i !== a.length - 1) {
        if (
          Date.parse(a[i].time) - Date.parse(a[i + 1].time) >
          threshold * 1000
        ) {
          // console.log(
          //   i,
          //   " comparing : ",
          //   a[i].time,
          //   a[i + 1].time,
          //   Date.parse(a[i].time),
          //   Date.parse(a[i + 1].time),
          //   Date.parse(a[i].time) - Date.parse(a[i + 1].time)
          // );
          count = count + 1;
        }
      }
    });
    return count;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        from,
        to,
        sensor: tabValue ? "sensor2" : "sensor1",
      }),
      redirect: "follow",
    };
    fetch("https://dashboard.grearoof.com/api/fetch-data", requestOptions)
      .then((response) => response.json())
      .then((result) => setData(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md" sx={{ paddingTop: 8 }}>
        <Typography
          variant="h3"
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          Sensor Dashboard
        </Typography>
        <CssBaseline />
        <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
          <Grid item xs={12} sm={12}>
            <Card
              sx={{ display: "flex", padding: 3, justifyContent: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ margin: 2, borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={(e, v) => handleChange(e, v, "tab")}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Cutter Sens" />
                    <Tab label="Gate Sens" />
                  </Tabs>
                </Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 1, marginBottom: 1 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={6}>
                      <DateTimePicker
                        label="From"
                        value={from}
                        fullWidth
                        onChange={(v) => handleChange(null, v, "from")}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePicker
                        label="To"
                        value={to}
                        fullWidth
                        onChange={(v) => handleChange(null, v, "to")}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
                <Grid container sx={{ marginTop: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                      size="small"
                      onClick={() => handleQuickSet(24)}
                    >
                      Past 1 day
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                      size="small"
                      onClick={() => handleQuickSet(12)}
                    >
                      Past 12 hrs
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{ marginLeft: 1, marginRight: 1 }}
                      size="small"
                      onClick={() => handleQuickSet(1)}
                    >
                      Past 1 hr
                    </Button>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Fetch
                </Button>
                <LastSeenFloating />
              </Box>
            </Card>
          </Grid>
          {data && (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    height: 200,
                  }}
                >
                  <Typography variant="h5" component="div">
                    {new Date(data.from).toLocaleString()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    to
                  </Typography>
                  <Typography variant="h5" component="div">
                    {new Date(data.to).toLocaleString()}
                  </Typography>
                  <Button sx={{ margin: 1 }}>View Full log</Button>
                </Card>
              </Grid>

              {data.sensor == "sensor1" && (
                <Grid item xs={12} sm={6}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      height: 200,
                    }}
                  >
                    <React.Fragment>
                      <Typography variant="h1" component="div">
                        {data.count}
                      </Typography>
                      <Typography variant="h6" component="div">
                        Total Cuts
                      </Typography>
                    </React.Fragment>
                  </Card>
                </Grid>
              )}
              {data.sensor == "sensor2" && (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 200,
                      }}
                    >
                      <React.Fragment>
                        <Typography variant="h1" component="div">
                          {gateSensorCounter(data.data)}
                        </Typography>
                        <Typography variant="h6" component="div">
                          Times gate opened
                        </Typography>
                      </React.Fragment>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 3,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h4" component="div">
                        Gate Sensor Threshold
                      </Typography>
                      <Typography variant="h6" component="div">
                        Skip Repeating pings every: {threshold} seconds
                      </Typography>
                      <Slider
                        aria-label="Temperature"
                        defaultValue={30}
                        value={threshold}
                        onChange={(e, v) => handleChange(e, v, "threshold")}
                        valueLabelDisplay="auto"
                        step={10}
                        marks
                        min={0}
                        max={120}
                      />
                    </Card>
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Home;

/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from "react";
import styles from "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "container/Home";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
